export const resources = {
  common: 'common',
  home: 'home',
  audit: 'audit'
};

export const translations = {
  common: {
    view: 'view',
    search: 'search',
    clear: 'clear',
    back: 'back',
    home: 'home',
    previous: 'previous',
    next: 'next',
    of: 'of',
    results: 'results',
    showing: 'showing',
    to: 'to'
  },
  audit: {
    list: {
      title: 'list.title',
      text: 'list.text',
      filter: {
        source: 'list.filter.source',
        level: 'list.filter.level',
        from: 'list.filter.from',
        to: 'list.filter.to',
        search: 'list.filter.search',
      },
      table: {
        header: {
          event: "list.table.header.event",
          source: "list.table.header.source",
          type: "list.table.header.type",
          time: "list.table.header.time"
        }
      }
    },
    details: {
      title: "details.title",
      text: "details.text",
      source: "details.source",
      eventName: "details.eventName",
      eventId: "details.eventId",
      level: "details.level",
      user: "details.user",
      logged: "details.logged",
      opCode: "details.opCode",
      category: "details.category",
      keyword: "details.keyword",
      computer: "details.computer",
      gdpr: "details.gdpr"
    }
  },
  home: {
    title: 'title',
    text: 'text',
    tiles: {
      title: 'tiles.title',
      error: {
        title: 'tiles.error.title'
      },
      warn: {
        title: 'tiles.warn.title'
      },
      info: {
        title: 'tiles.info.title'
      }
    },
    activities: {
      title: 'activities.title',
      text: 'activities.text'
    }
  },
};
