import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/outline";
import { useClassNames } from '@metaforcelabs/metaforce-core';
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { resources, translations } from "../../../translations/translationsConstants";

const StatusTile = ({ title, count, change, level, iconBgColor = 'red' || 'yellow' || 'blue', icon: Icon }) => {
    const { t } = useTranslation([resources.common]);
    const { classNames } = useClassNames();
  
    const getIconBgClassName = () => {
      switch (iconBgColor) {
        case 'red': return 'bg-red-500';
        case 'yellow': return 'bg-yellow-500';
        case 'blue': return 'bg-blue-500';
        default: return 'bg-blue-500';
      }
    }
  
    return <div
      className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
    >
      <dt>
        <div className={classNames("absolute rounded-md p-3", getIconBgClassName())}>
          <Icon className="h-6 w-6 text-white" aria-hidden="true" />
        </div>
        <p className="ml-16 text-sm font-medium text-gray-500 truncate">{title}</p>
      </dt>
      <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
        <p className="text-2xl font-semibold text-gray-900">{count || 0}</p>
        <p
          className={classNames(
            change === 0 ? 'text-gray-600' : change > 0 ? 'text-green-600' : 'text-red-600',
            'ml-2 flex items-baseline text-sm font-semibold'
          )}
        >
          {change === 0 ? (<span className='flex-shrink-0 text-gray text-lg font-bold mx-1'>-</span>) :
            change > 0 ? (
              <ArrowSmUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
            ) : (
              <ArrowSmDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
            )}
  
          <span className="sr-only">{change >= 0 ? 'Increased' : 'Decreased'} by</span>
          {change}
        </p>
        <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
          <div className="text-sm">
            <NavLink to={`/admin/audit?level=${level}&searchoninit=true`}
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              {' '}
              {t(translations.common.view)}
            </NavLink>
          </div>
        </div>
      </dd>
    </div>
  
  }

  export default StatusTile;