import { useClassNames } from '../../hooks/useClassNames';

export const Select = ({
  options,
  id,
  onChange,
  selectedValue = null,
  label = null,
  disabled = false,
  defaultOptionText = null,
  horizontal = false,
  className
}) => {
  const classes = useClassNames();
  return (
    <div className={classes.classNames(className, horizontal ? 'flex items-center mt-1' : '')}>
      {label && (
        <label
          htmlFor={id}
          className={classes.classNames(
            horizontal ? 'flex-1' : '',
            'block text-sm font-medium text-gray-700'
          )}
        >
          {label}
        </label>
      )}
      <select
        id={id}
        name={id}
        className={classes.classNames(
          horizontal ? 'flex-1' : '',
          label && 'mt-1',
          'block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm rounded-md'
        )}
        onChange={(evt) => onChange(evt.target.value, id)}
        value={selectedValue}
        disabled={disabled}
      >
        {defaultOptionText && <option value="">{defaultOptionText}</option>}
        {options.map((o) => {
          return (
            <option key={o.value} value={o.value}>
              {o.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
