import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { PlusIcon, TrashIcon } from '@heroicons/react/outline';
import ArrayUtils from '../../utils/array';
import toast from 'react-hot-toast';
import { convertToHumanFileSize } from '../../utils/file';
import { Button } from '../index';
import { useTranslation } from 'react-i18next';
import { resources, translations } from '../../translations/translationsConstants';

const DropField = ({ name, value, listLabel, onChange }) => {
  const [files, setFiles] = useState([]);

  const { t } = useTranslation([resources.orderCreate, resources.common]);

  const toggleClasses = (el, add, remove) => {
    el.classList.remove(remove);
    el.classList.add(add);
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections, e) => {
      if (fileRejections.length) {
        toast(t(translations.orderCreate.documentStep.fileWithWrongFormat), {
          duration: 4000,
          type: 'error'
        });
      }

      if (acceptedFiles.length) {
        const existFileWithSameName = ArrayUtils.duplicates(files, acceptedFiles, 'name');

        if (existFileWithSameName.length) {
          toast(t(translations.orderCreate.documentStep.fileWithTheSameNameExist), {
            duration: 4000,
            type: 'warning'
          });
        } else {
          const computedFiles = [...files, ...acceptedFiles];

          setFiles(computedFiles);
          onChange(computedFiles);
        }
      }

      toggleClasses(e.target, 'border-gray-300', 'border-brandPrimary');
    },
    [files]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.pdf',
    onDrop,
    onDragEnter: (e) => {
      if (e.target) {
        toggleClasses(e.target, 'border-brandPrimary', 'border-gray-300');
      }
    },
    onDragLeave: (e) => {
      if (e.target) {
        toggleClasses(e.target, 'border-gray-300', 'border-brandPrimary');
      }
    },
    noKeyboard: true
  });

  const handleOnDeleteClick = useCallback(
    (fileName) => {
      const computedFiles = files.filter((file) => file.name !== fileName);

      setFiles(computedFiles);
      onChange(computedFiles);
    },
    [files]
  );

  useEffect(() => {
    if (value) {
      setFiles(value);
    }
  }, [value]);

  return (
    <>
      <div className="grid grid-cols-5 gap-x-8">
        <div className="col-span-5 md:col-span-2" {...getRootProps()}>
          <div className="hidden md:flex justify-center items-center p-4 h-48 border-2 border-gray-300 transition-colors border-dashed rounded-md">
            <PlusIcon className="w-10 h-10" />
            <div className="flex flex-col ml-4 text-md text-gray-600">
              <div className="flex cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                <span>{t(translations.orderCreate.documentStep.clickToBrowse)}</span>
                <input id={name} name={name} type="file" className="sr-only" {...getInputProps()} />
              </div>
              <p className="hidden md:block">
                {t(translations.orderCreate.documentStep.orDragAndDrop)}
              </p>
              <p className="pt-2 text-xs text-gray-400">
                {t(translations.orderCreate.documentStep.filesSizesLimitInfo)}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-4 col-span-5 md:mt-0 md:col-span-3">
          <div className="flex items-center mb-2" {...getRootProps()}>
            <h4 className="text-sm font-medium text-gray-700">
              {listLabel || t(translations.orderCreate.documentStep.list)} ({files.length})
            </h4>

            <Button className="md:hidden ml-4" variant={Button.variants.primary}>
              {t(translations.orderCreate.documentStep.clickToBrowse)}
            </Button>
          </div>

          <ul className="list-inside max-h-40 overflow-y-auto nice-scroll">
            {files.map((file) => {
              return (
                <li
                  key={file.path}
                  className="flex py-3 text-sm first:border-0 border-t border-gray-200 text-gray-700"
                >
                  {file.name}

                  <span className="flex items-center ml-2 text-xs text-gray-400">
                    ({convertToHumanFileSize(file.size)})
                  </span>
                  <TrashIcon
                    className="ml-auto mr-2 w-5 h-5 cursor-pointer transition-colors hover:text-brandPrimary"
                    onClick={() => handleOnDeleteClick(file.name)}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default DropField;
