import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import {
  AuthenticationProvider,
  oidcLog,
  OidcSecure,
  useReactOidc,
  InMemoryWebStorage
} from '@axa-fr/react-oidc-context';
import { getUserManager } from '@axa-fr/react-oidc-core';
import { config, oidcConfig } from './utils/config';
import AdminLayout from './layouts/AdminLayout';
import Oidc from './screens/Oidc';
import { Unauthorized } from './screens/Errors/Unauthorized';
import { Unauthenticated } from './screens/Errors/Unauthenticated';
import { NotFound } from './screens/Errors/NotFound';
import { setAuthHeader } from './api/api';
import MissingLicense from './screens/Errors/MissingLicense';
import { getUserProfile } from './api/userProfile';
import { OidcRoutesContext } from './contexts';
import { getCustomerInfo } from './api/customer';
import { useToastAction } from './hooks/useToastAction';
import { useFeature } from './hooks/useFeature';
import { Dashboard } from './screens/Admin';
import { Toaster } from './toaster';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { WhiteLabelProvider } from '@metaforcelabs/metaforce-core';
import common_en from './translations/en/common.json';
import home_en from './translations/en/home.json';
import audit_en from './translations/en/audit.json';

import common_no from './translations/no/common.json';
import home_no from './translations/no/home.json';
import audit_no from './translations/no/audit.json';

import { useLocation } from 'react-router-dom';
import { useSessionStorage } from './hooks/useSessionStorage';
import React from 'react';
import Audit from './screens/Admin/Audit';
import EventLog from './screens/Admin/EventLog';

i18next.init({
  interpolation: {
    escapeValue: false
  },
  lng: 'en',
  resources: {
    en: {
      common: common_en,
      home: home_en,
      audit: audit_en
    },
    nb: {
      common: common_no,
      home: home_no,
      audit: audit_no
    }
  }
});

function App() {

  return (
    <div className="app h-screen">
      <Router>
        <AuthenticationProvider
          configuration={oidcConfig}
          loggerLevel={oidcLog.ERROR}
          isEnabled={true}
          UserStore={InMemoryWebStorage}
          callbackComponentOverride={Oidc}
          notAuthenticated={Unauthenticated}
          notAuthorized={() => {
            return <h1>Not Authorized.</h1>;
          }}
          authenticating={Oidc}
        >
          <Switch>
            <Route exact={true} path="/unauthorized" component={Unauthorized} />
            <Route exact={true} path="/missing-license" component={MissingLicense} />
            <Route exact={true} path="/not-found" component={NotFound} />

            <OidcSecure>
              <SetAuthToken>
                <SetOidcRoutesContext>
                  <I18nextProvider i18n={i18next}>
                    <WhiteLabelProvider
                      configuration={{
                        centerpointApiUrl: config.centerpointBaseUrl
                      }}
                    >
                      <AdminLayoutRoute
                        exact={true}
                        path="/admin/home"
                        nav="home"
                        component={Dashboard}
                        featureEnabled={true}
                      />

                      <AdminLayoutRoute
                        exact={true}
                        path="/"
                        nav="home"
                        component={Dashboard}
                        featureEnabled={true}
                      />
                      <AdminLayoutRoute
                        exact={true}
                        path="/admin/audit"
                        nav="audit"
                        component={Audit}
                        featureEnabled={true}
                      />
                      <AdminLayoutRoute
                        exact={true}
                        path="/admin/audit/:eventLogId"
                        nav="audit"
                        component={EventLog}
                        featureEnabled={true}
                      />
                    </WhiteLabelProvider>
                  </I18nextProvider>
                </SetOidcRoutesContext>
              </SetAuthToken>
            </OidcSecure>
          </Switch>
        </AuthenticationProvider>
      </Router>
      <Toaster />
    </div>
  );
}

function AdminLayoutRoute({
  component: Component,
  featureEnabled,
  nav,
  path,
  subMenuNav,
  requiredFeatureCode,
  ...rest
}) {
  const feature = useFeature();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {

  }, [location]);

  return (
    <ProtectedRoute
      featureEnabled={featureEnabled}
      hasFeature={feature.hasFeature(requiredFeatureCode)}
    >
      <Route
        path={path}
        {...rest}
        render={(props) => (
          <AdminLayout nav={nav} subMenuNav={subMenuNav} {...props}>
            <Component {...props} />
          </AdminLayout>
        )}
      />
    </ProtectedRoute>
  );
}

const ProtectedRoute = ({ featureEnabled, hasFeature, children }) => {
  return featureEnabled && hasFeature ? <>{children}</> : <Redirect to={'/'} />;
};

const SetOidcRoutesContext = ({ children }) => {
  const loadAction = useToastAction();
  const [userProfile, setUserProfile] = useState({});
  const [customerInfo, setCustomerInfo] = useState({});

  const loadUserContextData = async () => {
    loadAction.execute(async () => {
      const userProfilePromise = getUserProfile();
      const customerPromise = getCustomerInfo();
      const res = await Promise.all([userProfilePromise, customerPromise]);
      i18next.changeLanguage(res[0].languageCode);
      setUserProfile(res[0]);
      setCustomerInfo(res[1]);
    }, 'Failed to load user data');
  };

  useEffect(() => {
    loadUserContextData();
  }, []);

  return (
    !loadAction.isExecuting && (
      <OidcRoutesContext.Provider
        value={{
          userProfile,
          customerInfo
        }}
      >
        {children}
      </OidcRoutesContext.Provider>
    )
  );
};

/**
 * Small route wrapper that gets the oidc user and sets the axios auth header
 */
const SetAuthToken = ({ children }) => {
  const { oidcUser, events } = useReactOidc();

  setAuthHeader(oidcUser.access_token);

  useEffect(() => {
    if (!events) return;
    events.addUserSignedOut(addUserSignedOut);
    return () => {
      events.removeUserSignedOut(addUserSignedOut);
    };
  }, [events]);

  const addUserSignedOut = () => {
    const userManager = getUserManager();
    userManager.removeUser();
    window.location.reload(false);
  };

  return <>{children}</>;
};

export default App;
