import React, {  useState } from 'react'
import { AuditContext } from '../../../contexts';
import DateTimeParser from '../../../utils/DateTimeParser';
import AuditList from '../AuditList'

export default function Audit() {

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const queryPassedLevel = params.get('level');
    const searchOnInitParam = params.get('searchoninit')
    const [searchOnInit] = useState(!!searchOnInitParam);

    const formInitialValues = {
        source: '',
        level: queryPassedLevel ? queryPassedLevel : '',
        dateFrom: DateTimeParser.toLocaleDateString(DateTimeParser.subtract(new Date(), 0, 1, 0)),
        dateTo: DateTimeParser.toLocaleDateString(new Date()),
        search: ''
    }

    const [searchResult, setSearchResult] = useState({
        searchTimeSeconds: 0,
        totalRecords: 0
    });

    const [selectedEventLog, setSelectedEventLog] = useState(null);
    const [formValues, setFormValues] = useState(formInitialValues);

    return <AuditContext.Provider value={{
        searchResult: searchResult,
        setSearchResult: setSearchResult
    }}>
        <AuditList
            formValues={formValues}
            setFormValues={setFormValues}
            formInitialValues={formInitialValues}
            searchOnInit={searchOnInit} />
    </AuditContext.Provider >
}
