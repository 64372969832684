import { Field, ErrorMessage } from 'formik';

export const FormikSelectInput = ({
  name,
  label,
  formikProps,
  options,
  onBlur,
  onChange,
  type = null,
  placeholder = null,
  required = false,
}) => {
  const hasError = formikProps?.errors && formikProps.errors[name];
  const errorClasses = 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500'
  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
        {required === true && <span className="text-red-400"> *</span>}
      </label>
      <div className="mt-1">
        <Field
          as={type || "select"}
          name={name}
          className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400 ${hasError ? errorClasses : ''}`}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={(e) => {
            formikProps.setFieldValue(name, e.target.value);
            onChange && onChange()
          }}
        >
        {
          options.map(option => {
            return (
              <option key={option.value} value={option.value}>{option.name}</option>
            );
          })
        }
        </Field>
        <ErrorMessage name={name} component="div" className="text-red-500" />
      </div>
    </div>
  )
}