import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import './styles.scss';
import { config } from '../../utils/config';
import { setAuthHeader } from '../../api/api';
import { getCustomerDetails } from '../../api/customer';
import { MenuBar } from '@metaforcelabs/metaforce-core';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Oidc = () => {
  const history = useHistory();
  const { oidcUser } = useReactOidc();

  useEffect(() => {
    if (oidcUser) {
      setAuthHeader(oidcUser.access_token);
      validateLicense();
    }
  }, [oidcUser]);

  const navigationMenu = [
    {
      linkElement: <Link href="/admin/home">Home</Link>,
      name: "Home",
      current: true,
      featureEnabled: true
    }
  ];

  const validateLicense = async () => {
    const customerDetails = await getCustomerDetails();
    const hasLicense = customerDetails?.activeProducts?.find((a) => a.id === config.productLicense);

    if (hasLicense) {
      const url = new URL(window.location.href);
      const hasParamsURL = url.pathname.substring(1);

      history.push(`/${hasParamsURL}`);
    } else {
      history.push('/missing-license');
    }
  };

  return (
    <div className="fixed flex flex-col bg-gray-50 h-screen w-screen overflow-auto">
    <MenuBar
      subMenuNav={'releasePackages'}
      navigation={navigationMenu}
      homeButtonLink={config.centerpointUiBaseUrl}
    />
      <main
        className="main-container bg-brand-background focus:outline-none h-full"
      >
        <div className="max-w-screen-2xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col pb-10 h-full">
            <div className="max-w-screen-2xl">
              <p className="text-2xl font-bold mt-6">Loading...</p>
            </div>
          </div>
        </div>
      </main>
  </div>
  );
};

export default Oidc;
