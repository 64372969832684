import { useClassNames, useToastAction } from '@metaforcelabs/metaforce-core';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { downloadEventLog, getEventLog } from '../../../api/audit';
import { Button } from '../../../components'
import { resources, translations } from '../../../translations/translationsConstants';
import { logLevels, logSources } from '../../../utils/constants'
import DateTimeParser from '../../../utils/DateTimeParser';
import { isDate } from 'date-fns';
import { isString } from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const EventLogField = ({ label, value }) => {
    return (
        <>
            <div className='col-span-3 mt-2'>
                <label className="block text-sm font-medium text-gray-700 mt-2 ">
                    {label}
                </label>
            </div>
            <div className="sm:col-span-9 mt-2">
                <input
                    type={'text'}
                    className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400 bg-gray-100`}
                    disabled={true}
                    value={value}
                />
            </div>
        </>
    );
}

export default function EventLog({ }) {
    const { t: ta } = useTranslation([resources.audit])
    const { t: tc } = useTranslation([resources.common])
    const loadAction = useToastAction();
    const { eventLogId } = useParams();
    const [eventLog, setEventLog] = useState()
    const history = useHistory();
    const location = useLocation();
    console.log(location);
    const load = () => {
        loadAction.execute(async () => {
            const eventLogData = await getEventLog(eventLogId);
            const timeStamp = eventLogData.description['Time stamp (UTC)'];
            if (timeStamp && isString(timeStamp)) {
                eventLogData['Time stamp (UTC)'] = DateTimeParser.toUtcDateTimeString(timeStamp);
            }
            setEventLog(eventLogData);
        }, 'Failed to load eventlog')
    }

    useEffect(() => {
        load();
    }, []);

    const handleBackClick = () => {
        if (location.search && new URLSearchParams(location.search).get('source')) {
            history.goBack();
        } else {
            history.push('/admin/audit');
        }

    }

    return eventLog ? (
        <div className="w-full h-full" >
            <h1 className="text-3xl font-bold mb-6">{ta(translations.audit.details.title)}</h1>
            {/* <h3 className="mt-4 text-gray-600">{'Search and filter event logs'}</h3> */}
            <div className="md:grid grid-cols-2 md:space-x-4 mb-4">
                <div className='max-w-xl'>
                    <div className='sm:grid grid-cols-12'>

                        {/* Source */}
                        <EventLogField label={ta(translations.audit.details.source)} value={logSources.find(x => x.value === eventLog?.source)?.name || eventLog?.source} />

                        {/* Event Name */}
                        <EventLogField label={ta(translations.audit.details.eventName)} value={eventLog?.logName} />

                        {/* Event ID */}
                        <EventLogField label={ta(translations.audit.details.eventId)} value={eventLog?.eventId} />

                        {/* Level */}
                        <EventLogField label={ta(translations.audit.details.level)} value={logLevels.find(x => x.value === eventLog?.level)?.name || eventLog?.level} />

                        {/* User */}
                        <EventLogField label={ta(translations.audit.details.user)} value={eventLog?.user} />

                        {/* Logged*/}
                        <EventLogField label={ta(translations.audit.details.logged)} value={DateTimeParser.toLocaleDateTimeString(eventLog?.createdDate)} />

                        {/* OpCode*/}
                        <EventLogField label={ta(translations.audit.details.opCode)} value={eventLog?.opCode} />

                        {/* Category */}
                        <EventLogField label={ta(translations.audit.details.category)} value={eventLog?.category} />

                        {/* Keyword */}
                        <EventLogField label={ta(translations.audit.details.keyword)} value={eventLog?.keywords} />

                        {/* Computer */}
                        <EventLogField label={ta(translations.audit.details.computer)} value={eventLog?.computer} />

                        {/* GDPR */}
                        <EventLogField label={ta(translations.audit.details.gdpr)} value={eventLog?.gdpr ? 'True' : 'False'} />

                    </div>
                </div>
                <div className='p-4 border rounded-md mt-2 shadow-sm border-gray-300'>
                    {
                        eventLog.description && (
                            <div className='ml-1'>
                                <SimpleJsonViewer data={eventLog.description} />
                            </div>
                        )
                    }
                </div>
            </div>
            <div className='flex justify-end'>
                <Button variant={Button.variants.primary}
                    onClick={() => handleBackClick()}>
                    {tc(translations.common.back)}
                </Button>
            </div>
        </div >
    ) : (<></>)
}

const SimpleJsonViewer = ({ data, level = 0 }) => {
    return (
        <ul className='list-disc ml-4'>
            {
                Object.keys(data).map(k => {
                    const value = data[k];
                    if (value === null) {
                        return <li key={k}><span className='font-semibold'>{k}: </span><span /></li>;
                    }

                    const type = typeof (value)
                    const isDate = type === 'string' && (DateTimeParser.valueIsDate(value) || DateTimeParser.valueIsJSONDate(value));
                    return (<li key={k}>
                        <span className='font-semibold'>{k}: </span>
                        {
                            type === 'object' && (
                                <SimpleJsonViewer data={value} level={level + 1} />
                            )
                        }
                        {
                            type !== 'object' && !isDate && (<span>{value}</span>)
                        }
                        {
                            type !== 'object' && isDate && (<span>{DateTimeParser.toLocaleDateTimeString(value)}</span>)
                        }
                    </li>)
                })
            }
        </ul>
    )
}
