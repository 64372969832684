import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import LoaderSpinner from 'react-loader-spinner';

export default function Loader({ className }) {
  return (
    <div
      className={className}
      style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
    >
      <LoaderSpinner type="Oval" color="#2b59c0" height={25} width={25} />
    </div>
  );
}
