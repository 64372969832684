import toast, { useToaster } from 'react-hot-toast';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { XCircleIcon } from '@heroicons/react/outline';
import { ExclamationIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { useClassNames } from '../../hooks/useClassNames';

const Notifications = () => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause } = handlers;
  const classNames = useClassNames();

  const hideToast = (toastId) => {
    toast.remove(toastId);
    endPause();
  };

  return (
    <>
      <div className={'fixed top-5 right-5 z-20'} onMouseEnter={startPause} onMouseLeave={endPause}>
        {toasts.map((toast) => {
          return (
            <div
              key={toast.id}
              className={classNames.classNames(
                'max-w-sm w-full shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden mb-4',
                !toast.type && 'bg-white',
                toast.type === 'warning' && 'bg-yellow-100',
                toast.type === 'error' && 'bg-red-100'
              )}
            >
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {toast.type === 'success' && (
                      <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                    )}
                    {toast.type === 'error' && (
                      <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                    )}
                    {toast.type === 'warning' && (
                      <ExclamationIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />
                    )}
                  </div>
                  <div className="ml-3 flex-1 pt-0.5">
                    <p
                      className={classNames(
                        'text-sm font-medium',
                        !toast.type && 'text-gray-900',
                        toast.type === 'warning' && 'text-yellow-500',
                        toast.type === 'error' && 'text-red-500'
                      )}
                    >
                      {toast.message}
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className={classNames(
                        'rounded-md inline-flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 ',
                        !toast.type && 'bg-white text-gray-400 hover:text-gray-500',
                        toast.type === 'warning' && 'text-yellow-500',
                        toast.type === 'error' && 'text-red-500'
                      )}
                      onClick={() => hideToast(toast.id)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Notifications;
