import { Form, Formik } from 'formik';
import { t } from 'i18next';
import { useToastAction, Table as CoreTable, useServerSideTableActions } from '@metaforcelabs/metaforce-core';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { searchAuditLogs } from '../../../api/audit';
import { Button } from '../../../components';
import DateField from '../../../components/DateField';
import FormikInput from '../../../components/Form/Formik/FormikInput';
import { FormikSelectInput } from '../../../components/Form/Formik/FormikSelect';
import { FormikSubmitButton } from '../../../components/Form/Formik/FormikSubmit';
import { Table, TableBody, TableColumn, TableHead, TableHeader, TableRow } from '../../../components/Table';
import { AuditContext } from '../../../contexts';
import { resources, translations } from '../../../translations/translationsConstants';
import { logLevels, logSources } from '../../../utils/constants';
import DateTimeParser from '../../../utils/DateTimeParser';

export default function AuditList({ formValues, setFormValues, formInitialValues, searchOnInit }) {
    const { t: ta } = useTranslation([resources.audit]);
    const { t: tc } = useTranslation([resources.common]);
    const { searchResult, setSearchResult } = useContext(AuditContext);
    const [pageData, setPageData] = useState([]);
    const searchAction = useToastAction();
    const pageSize = 15;

    const getNextPage = (skip, take) => {

        handleSearch(skip, take, formValues, () => { })
    }
    const [isSearching, setIsSearching] = useState(false);
    const tableActions = useServerSideTableActions(pageData || [], pageSize, searchResult.totalRecords, getNextPage);

    const history = useHistory();

    const handleSearch = async (skip, take, values, onSearchComplete) => {
        const searchFilter = {
            ...values,
            dateFrom: DateTimeParser.toLocalJSONFormat(DateTimeParser.setSOD(values.dateFrom)),
            dateTo: DateTimeParser.toLocalJSONFormat(DateTimeParser.setSOD(values.dateTo)),
            source: +values.source || null,
            level: +values.level || null,
            skip: skip,
            take: take
        }
        searchAction.execute(async () => {
            try {

                const { eventLogs, ...searchData } = await searchAuditLogs(searchFilter);

                setSearchResult(searchData);
                setPageData(eventLogs);

                onSearchComplete();
            } catch (error) {
                onSearchComplete();
                throw error;
            }
        }, "Search Failed")
    }

    const handleClear = (props) => {
        setFormValues(formInitialValues);
        props.resetForm();
        handleSearch(tableActions.skip, tableActions.pageSize, formInitialValues, () => props.setSubmitting(false));
        tableActions.reset();
    }

    const handleEventLogClick = (log) => {
        history.push(`/admin/audit/${log.id}`);
    };

    useEffect(() => {
        if (searchOnInit) {
            handleSearch(tableActions.skip, tableActions.pageSize, formValues, () => { })
            history.replace({ search: '' })
        }
    }, [])

    return (
        <div className="w-full h-full">
            <h1 className="text-3xl font-bold">{ta(translations.audit.list.title)}</h1>
            <h3 className="mt-4 text-gray-600">{ta(translations.audit.list.text)}</h3>

            {/* Filter */}
            <div>
                <>
                    <Formik
                        initialValues={formValues}
                        onSubmit={(values, actions) => {
                            tableActions.reset();
                            setFormValues(values);
                            handleSearch(tableActions.skip, tableActions.pageSize, values, () => actions.setSubmitting(false));
                        }}
                    >
                        {(props) => (
                            <Form>
                                <div className="w-full mt-5 mb-10 pb-5 border-b border-gray-200">
                                    <div className="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-4 lg:grid-cols-6">
                                        <div className="col-span-1 sm:col-span-2">
                                            <FormikSelectInput
                                                label={ta(translations.audit.list.filter.source)}
                                                name="source"
                                                formikProps={props}
                                                options={logSources}
                                            />
                                        </div>
                                        <div className="col-span-1 sm:col-span-2">
                                            <FormikSelectInput
                                                label={ta(translations.audit.list.filter.level)}
                                                name="level"
                                                formikProps={props}
                                                options={logLevels}
                                            />
                                        </div>

                                        <div className="col-span-1 sm:col-span-1 sm:mb-auto mb-6">
                                            <DateField
                                                navbar={true}
                                                label={ta(translations.audit.list.filter.from)}
                                                name="dateFrom"
                                                value={props.values.dateFrom}
                                                maxDate={props.values.dateTo}
                                                onChange={(v, e) => {
                                                    props.setFieldValue('dateFrom', v);
                                                }}
                                            />
                                        </div>

                                        <div className="col-span-1 sm:col-span-1 sm:mb-auto mb-6">
                                            <DateField
                                                navbar={true}
                                                label={ta(translations.audit.list.filter.to)}
                                                name="dateTo"
                                                value={props.values.dateTo}
                                                minDate={props.values.dateFrom}
                                                onChange={(v, e) => {
                                                    props.setFieldValue('dateTo', v);
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-1 sm:col-span-2">
                                            <FormikInput
                                                label={ta(translations.audit.list.filter.search)}
                                                name="search"
                                                formikProps={props}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex justify-end mt-6">
                                        <Button variant={Button.variants.secondary}
                                            onClick={() => { handleClear(props) }}>
                                            {tc(translations.common.clear)}
                                        </Button>

                                        <FormikSubmitButton
                                            id="search"
                                            text={tc(translations.common.search)}
                                            className="ml-2 i"
                                            formikProps={props}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
            </div>

            <div className="relative">
                <Table pagination={() => <CoreTable.Pagination tableActions={tableActions} translations={{
                    previous: tc(translations.common.previous),
                    next: tc(translations.common.next),
                    of: tc(translations.common.of),
                    results: tc(translations.common.results),
                    showing: tc(translations.common.showing),
                    to: tc(translations.common.to),
                }} />}>
                    <TableHead>
                        <TableHeader>{ta(translations.audit.list.table.header.event)}</TableHeader>
                        <TableHeader>{ta(translations.audit.list.table.header.source)}</TableHeader>
                        <TableHeader>{ta(translations.audit.list.table.header.type)}</TableHeader>
                        <TableHeader>{ta(translations.audit.list.table.header.time)}</TableHeader>
                    </TableHead>
                    <TableBody>
                        {!isSearching && (tableActions.pageData || []).map((item, index) =>
                        (<React.Fragment key={index}>
                            <TableRow className={index % 2 === 0 ? undefined : 'bg-gray-50'}
                                onClick={e => {
                                    handleEventLogClick(item);
                                    // onSelectEventLog(item);
                                }}
                            >
                                <TableColumn
                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer text-sm font-medium text-indigo-600 hover:text-indigo-500"
                                >
                                    {item.logName}
                                </TableColumn>
                                <TableColumn
                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer"
                                >
                                    {logSources.find(x => x.value === item.source)?.name || item.source}
                                </TableColumn>
                                <TableColumn
                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer"
                                >
                                    {logLevels.find(x => x.value === item.level)?.name || item.level}
                                </TableColumn>
                                <TableColumn
                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer"
                                >
                                    {DateTimeParser.toLocaleDateTimeString(item.createdDate)}
                                </TableColumn>
                            </TableRow>
                        </React.Fragment>)
                        )
                        }
                        {
                            isSearching && ('Loading ...')
                        }
                    </TableBody>
                </Table>

            </div>

        </div>
    )
}
