import { get, BaseUrl, post } from './api';

const controllerName = 'api/Audit';

export const searchAuditLogs = async (searchFilter) => {
    return await post(controllerName, searchFilter);
}

export const getEventLog = async (id)=>{
    return await get(controllerName + "/" + id);
}