/**
 * Lookup an item in the array, and replace it with another item
 * @param {[]} array
 * @param {([]) => boolean} predicate
 * @param {any} newItem
 */

const ArrayUtils = {
  duplicates: (firstArray, secondArray, key) => {
    const firstArrayFlat = firstArray.map((v) => v[key]);
    const secondArrayFlat = secondArray.map((v) => v[key]);

    return firstArrayFlat.filter((val) => secondArrayFlat.indexOf(val) !== -1);
  },

  replaceItem: (array, predicate, newItem) => {
    const index = array.findIndex(predicate);
    if (index > -1) {
      array.splice(index, 1, newItem);
    }
  },

  moveItem: (arr, fromIndex, toIndex) => {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }
};

export default ArrayUtils;
