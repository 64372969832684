import {
  ExclamationCircleIcon,
  ExclamationIcon,
  InformationCircleIcon
} from '@heroicons/react/outline';
import './styles.scss';
import React, { useEffect, useState, } from 'react';
import { useToastAction } from '../../../hooks/useToastAction';
import { useTranslation } from 'react-i18next';
import { resources, translations } from '../../../translations/translationsConstants';
import { useClassNames } from '@metaforcelabs/metaforce-core';
import { getDashboard } from '../../../api/dashboard';
import { Table, TableBody, TableColumn, TableHead, TableHeader, TableRow } from '../../../components/Table';
import DateTimeParser from '../../../utils/DateTimeParser';
import { logLevels, logLevelTypes, logSources } from '../../../utils/constants';
import EventLog from '../EventLog';
import StatusTile from './statusTile';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { AuditDisplayArt } from '../../../layouts/AdminLayout/auditDisplayArt';


const Dashboard = () => {
  const { t } = useTranslation([resources.home]);
  const { t: ta } = useTranslation([resources.audit]);
  const loadAction = useToastAction();
  const [dashboard, setDashboard] = useState(null)
  const { classNames } = useClassNames();
  const [selectedLog, setSelectedLog] = useState(undefined);
  const history = useHistory();
  const loadDashboard = async () => {
    loadAction.execute(async () => {
      const dashboardData = await getDashboard();
      setDashboard(dashboardData);
    }, "Failed to load dashboard");
  }

  useEffect(() => {
    loadDashboard();
  }, []);

  const statusTiles = [
    {
      title: t(translations.home.tiles.error.title),
      count: dashboard?.errors,
      change: dashboard?.errorChange,
      level: logLevelTypes.error,
      iconBgColor: 'red',
      icon: ExclamationCircleIcon
    },
    {
      title: t(translations.home.tiles.warn.title),
      count: dashboard?.warnings,
      change: dashboard?.warningsChange,
      level: logLevelTypes.warn,
      iconBgColor: 'yellow',
      icon: ExclamationIcon
    },
    {
      title: t(translations.home.tiles.info.title),
      count: dashboard?.infos,
      change: dashboard?.infosChange,
      level: logLevelTypes.info,
      iconBgColor: 'blue',
      icon: InformationCircleIcon
    }
  ]

  const handleEventLogClick = (log) => {
    history.push(`/admin/audit/${log.id}?source=dashboard`);
  }

  return (
    <div className="w-full h-full">
      <h1 className="mt-5 font-bold text-2xl sm:text-4xl">{t(translations.home.title)}</h1>

      <div className="mt-10 h-full gap-x-10 w-full px-6 sm:px-8 lg:px-12 py-6 lg:py-8 rounded-md sm:grid grid-cols-12 bg-document-yellow-lightest">
        <div className="space-y-3 col-span-12 sm:col-span-7 lg:col-span-8">
          <div className="block sm:inline-block w-full font-medium text-sm text-gray-600">
            The audit function in the Centerpoint application is designed to meticulously track and record key events throughout the application.
            This ensures comprehensive monitoring, accountability, and compliance with operational standards.
            The function provides real-time visibility into the application's activities, facilitating effective management and quick identification of issues.
          </div>
        </div>
        <div className="hidden sm:block sm:col-span-5 lg:col-span-4 my-auto">
          <AuditDisplayArt/>
        </div>
      </div>

      <div className="mt-10">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{t(translations.home.tiles.title)}</h3>

        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {
            statusTiles.map(tile => <StatusTile {...tile} />)
          }
        </dl>
      </div>

      <div className="mt-10 overflow-hidden sm:rounded-md grid grid-cols-1">
        <div className="mb-6">
          <h2 className="col-start-1 text-lg font-medium text-gray-900">
            {t(translations.home.activities.title)}
          </h2>

          <p className="col-start-1 mt-1 text-sm text-gray-500">
            {t(translations.home.activities.text)}
          </p>
        </div>

        <div className="relative">
          <Table>
            <TableHead>
              <TableHeader>{ta(translations.audit.list.table.header.event)}</TableHeader>
              <TableHeader>{ta(translations.audit.list.table.header.source)}</TableHeader>
              <TableHeader>{ta(translations.audit.list.table.header.type)}</TableHeader>
              <TableHeader>{ta(translations.audit.list.table.header.time)}</TableHeader>
            </TableHead>
            <TableBody>
              {(dashboard?.eventLogs || []).map((item, index) =>
              (<React.Fragment key={index}>
                <TableRow className={index % 2 === 0 ? undefined : 'bg-gray-50'}
                  onClick={e => {
                    handleEventLogClick(item);
                  }}
                >
                  <TableColumn
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    {item.logName}
                  </TableColumn>
                  <TableColumn
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer"
                  >
                    {logSources.find(x => x.value === item.source)?.name || item.source}
                  </TableColumn>
                  <TableColumn
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer"
                  >
                    {logLevels.find(x => x.value === item.level)?.name || item.level}
                  </TableColumn>
                  <TableColumn
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer"
                  >
                    {DateTimeParser.toLocaleDateTimeString(item.createdDate)}
                  </TableColumn>
                </TableRow>
              </React.Fragment>)
              )
              }
            </TableBody>
          </Table>
        </div>
      </div>
    </div >
  );
};

export default Dashboard;
