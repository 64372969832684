import { useContext } from 'react';
import './styles.scss';
import { config } from '../../utils/config';
import { OidcRoutesContext } from '../../contexts';
import { MenuBar, useWhiteLabel } from '@metaforcelabs/metaforce-core';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const AdminLayout = ({ nav, children }) => {
  const oidcRoutesContext = useContext(OidcRoutesContext);
  const { isOnlySigningCreator } = useSessionStorage();
  const { bgColor } = useWhiteLabel();

  const navigationMenu = [
    {
      linkElement: <Link to="/admin/home">Home</Link>,
      name: "Home",
      current: nav === "home",
      featureEnabled: !isOnlySigningCreator()
    },
    {
      linkElement: <Link to="/admin/audit">Audit</Link>,
      name: "Audit",
      current: nav === "audit",
      featureEnabled: !isOnlySigningCreator(),
    },
  ];

  return (
    <div className="fixed flex flex-col bg-gray-50 h-screen w-screen overflow-auto">
      <MenuBar
        subMenuNav={'releasePackages'}
        navigation={navigationMenu}
        customerName={oidcRoutesContext.customerInfo.name}
        customerOrgNumber={oidcRoutesContext.customerInfo.orgNumber}
        homeButtonLink={config.centerpointUiBaseUrl}
        userProfileImage={oidcRoutesContext.userProfile.userProfileImage}
        userName={oidcRoutesContext.userProfile.fullName}
        helpLink={config.docsUrl}
        maxWidthOverride={"max-w-screen-2xl"}
      />
      <main
        style={{
          backgroundColor: bgColor
        }}
        className="main-container bg-brand-background focus:outline-none h-full"
      >
        <div className="max-w-screen-2xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">

          <div className="flex flex-col pb-10 h-full">
            <div className="max-w-screen-2xl">{children}</div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AdminLayout;
