import React from "react"
import sampleImg from '../../assets/images/sample-dashboard-audit.png';

export const AuditDisplayArt = ({ }) => {
    return (
        <div className='w-full flex justify-end'>
            <div className='relative h-24 p-1 overflow-hidden'>
                <img src={sampleImg} className="w-24 rounded-3xl mr-10" alt="sample" />
            </div>
        </div>
    )
}
