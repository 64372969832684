export const useSessionStorage = () => {
  const isOnlySigningCreator = 'isOnlySigningCreator';

  const setSessionStorageItem = (key, value) => sessionStorage.setItem(key, value);

  const getSessionStorageItem = (key) => sessionStorage.getItem(key);

  const removeSessionStorageItem = (key) => sessionStorage.removeItem(key);

  return {
    isOnlySigningCreator: () => getSessionStorageItem(isOnlySigningCreator),
    removeIsOnlySigningCreator: () => removeSessionStorageItem(isOnlySigningCreator),
    setIsOnlySigningCreator: () => setSessionStorageItem(isOnlySigningCreator, true)
  };
};
