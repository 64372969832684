import { Field } from 'formik';
import FormikErrorMessage from './FormikErrorMessage';
import { useCallback, useState } from 'react';

const errorClasses = 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500';

export default function FormikInput({
  name,
  label,
  onChange,
  isAutoCompleteDisabled,
  isAutoCompleteOnlyOnFocus,
  type = null,
  placeholder = null,
  required = false
}) {
  const [autocomplete, setAutocomplete] = useState(() =>
    isAutoCompleteDisabled || isAutoCompleteOnlyOnFocus ? 'new-password' : ''
  );

  const handleOnFocus = useCallback(() => {
    if (isAutoCompleteOnlyOnFocus) {
      setAutocomplete('');
    }
  }, [isAutoCompleteOnlyOnFocus]);

  const handleOnBlur = useCallback(() => {
    if (isAutoCompleteOnlyOnFocus) {
      setAutocomplete('new-password');
    }
  }, [isAutoCompleteOnlyOnFocus]);

  return (
    <div>
      {label && (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {label}
          {required === true && <span className="text-red-400"> *</span>}
        </label>
      )}

      <Field name={name}>
        {({ field, meta }) => (
          <div className={label ? 'mt-1' : ''}>
            <input
              type={type || 'text'}
              className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400 ${
                meta.touched && meta.error ? errorClasses : ''
              }`}
              placeholder={placeholder}
              {...field}
              onChange={(e) => {
                field.onChange(e);
                if (onChange) {
                  onChange(e);
                }
              }}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              autoComplete={autocomplete}
            />
            <FormikErrorMessage name={name} />
          </div>
        )}
      </Field>
    </div>
  );
}
