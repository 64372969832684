import { Redirect } from 'react-router-dom';
import { useReactOidc } from '@axa-fr/react-oidc-context';

export const Unauthenticated = () => {
    const { oidcUser } = useReactOidc();  

    if(!(oidcUser && oidcUser.expires_in)) 
    {
      window.sessionStorage.clear();
    }
    
    return (
      <div>
          <h1>You are not authenticated.</h1>
          <Redirect to='/' />
      </div>
    );
  }