export const objectToJsonFile = (document, obj, fileName) => {
  const element = document.createElement('a');
  const file = new Blob([JSON.stringify(obj)], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = `${fileName}.json`;
  document.body.appendChild(element);
  element.click();
};

export const convertToHumanFileSize = (bytes, si=true, dp=1) => {
  const thresh = 1000; // si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};
